/* src/components/DocumentCard.module.css */
.card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
  }
  
  .card-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .label {
    font-weight: 600;
  }
  