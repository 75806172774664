.table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  table {
    min-width: 600px;
  }
  
  th,
  td {
    white-space: nowrap;
  }
  